import * as i0 from '@angular/core';
import { ElementRef, Component, ChangeDetectionStrategy, Inject, Optional, NgModule } from '@angular/core';
import * as i3 from '@angular/forms';
import { NgControl } from '@angular/forms';
import * as i4 from '@taiga-ui/cdk';
import { tuiTypedFromEvent, TUI_DEFAULT_IDENTITY_MATCHER, tuiIsPresent, AbstractTuiControl } from '@taiga-ui/cdk';
import * as i1 from '@taiga-ui/core';
import { TUI_COMMON_ICONS, TUI_DATA_LIST_HOST, TuiOptionComponent, TuiDataListComponent, TuiSvgModule, TuiScrollIntoViewModule } from '@taiga-ui/core';
import { POLYMORPHEUS_CONTEXT, PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { Subject, merge, EMPTY } from 'rxjs';
import { startWith, map, distinctUntilChanged } from 'rxjs/operators';
import * as i2 from '@angular/common';
import { CommonModule } from '@angular/common';
function TuiSelectOptionComponent_tui_svg_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "tui-svg", 3);
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵproperty("src", ctx_r0.icons.check)("tuiScrollIntoView", true);
  }
}
function TuiSelectOptionComponent_ng_template_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelement(0, "span", 4);
  }
}
class TuiSelectOptionComponent {
  constructor(icons, context, host, el, option, dataList, control, abstractControl) {
    this.icons = icons;
    this.context = context;
    this.host = host;
    this.el = el;
    this.option = option;
    this.dataList = dataList;
    this.control = control;
    this.abstractControl = abstractControl;
    this.changeDetection$ = new Subject();
    this.selected$ = merge(this.changeDetection$, this.control.valueChanges || EMPTY, tuiTypedFromEvent(this.el.nativeElement, 'animationstart')).pipe(startWith(null), map(() => this.selected), distinctUntilChanged());
  }
  get matcher() {
    return this.host.identityMatcher || TUI_DEFAULT_IDENTITY_MATCHER;
  }
  ngOnInit() {
    /**
     * This would cause changes inside already checked parent component (during the same change detection cycle),
     * and it might cause ExpressionChanged error due to potential HostBinding
     * (for example, inside {@link https://github.com/angular/angular/blob/main/packages/forms/src/directives/ng_control_status.ts#L99 NgControlStatus}).
     * Microtask keeps it in the same frame but allows change detection to run.
     */
    void Promise.resolve().then(() => {
      var _a, _b;
      if (tuiIsPresent(this.option.value) && !this.option.disabled) {
        (_b = (_a = this.host).checkOption) === null || _b === void 0 ? void 0 : _b.call(_a, this.option.value);
      }
    });
  }
  ngDoCheck() {
    this.changeDetection$.next();
  }
  get value() {
    var _a, _b;
    return (_b = (_a = this.abstractControl) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : this.control.value;
  }
  get selected() {
    return tuiIsPresent(this.option.value) && tuiIsPresent(this.value) && this.matcher(this.value, this.option.value);
  }
}
TuiSelectOptionComponent.ɵfac = function TuiSelectOptionComponent_Factory(t) {
  return new (t || TuiSelectOptionComponent)(i0.ɵɵdirectiveInject(TUI_COMMON_ICONS), i0.ɵɵdirectiveInject(POLYMORPHEUS_CONTEXT), i0.ɵɵdirectiveInject(TUI_DATA_LIST_HOST), i0.ɵɵdirectiveInject(ElementRef), i0.ɵɵdirectiveInject(TuiOptionComponent), i0.ɵɵdirectiveInject(TuiDataListComponent, 8), i0.ɵɵdirectiveInject(NgControl), i0.ɵɵdirectiveInject(AbstractTuiControl, 8));
};
TuiSelectOptionComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TuiSelectOptionComponent,
  selectors: [["tui-select-option"]],
  decls: 5,
  vars: 5,
  consts: [["dummy", ""], [3, "ngTemplateOutlet"], ["automation-id", "tui-select-option__checkmark", "class", "t-checkmark", 3, "src", "tuiScrollIntoView", 4, "ngIf", "ngIfElse"], ["automation-id", "tui-select-option__checkmark", 1, "t-checkmark", 3, "src", "tuiScrollIntoView"], [1, "t-dummy"]],
  template: function TuiSelectOptionComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵelementContainer(0, 1);
      i0.ɵɵtemplate(1, TuiSelectOptionComponent_tui_svg_1_Template, 1, 2, "tui-svg", 2);
      i0.ɵɵpipe(2, "async");
      i0.ɵɵtemplate(3, TuiSelectOptionComponent_ng_template_3_Template, 1, 0, "ng-template", null, 0, i0.ɵɵtemplateRefExtractor);
    }
    if (rf & 2) {
      const dummy_r2 = i0.ɵɵreference(4);
      i0.ɵɵproperty("ngTemplateOutlet", ctx.context.$implicit);
      i0.ɵɵadvance();
      i0.ɵɵproperty("ngIf", i0.ɵɵpipeBind1(2, 3, ctx.selected$))("ngIfElse", dummy_r2);
    }
  },
  dependencies: [i1.TuiSvgComponent, i2.NgTemplateOutlet, i2.NgIf, i1.TuiScrollIntoViewDirective, i2.AsyncPipe],
  styles: ["@keyframes _ngcontent-%COMP%_retrigger{0%{left:1px}to{left:2px}}[_nghost-%COMP%]{display:flex;flex:1;align-items:center;max-width:100%;animation:_ngcontent-%COMP%_retrigger 1s}.t-checkmark[_ngcontent-%COMP%]{-webkit-margin-start:auto;margin-inline-start:auto;-webkit-border-start:.25rem solid transparent;border-inline-start:.25rem solid transparent}tui-data-list[data-list-size=\"s\"][_nghost-%COMP%]   .t-checkmark[_ngcontent-%COMP%], tui-data-list[data-list-size=\"s\"]   [_nghost-%COMP%]   .t-checkmark[_ngcontent-%COMP%]{-webkit-margin-end:-.375rem;margin-inline-end:-.375rem}tui-data-list[data-list-size=\"m\"][_nghost-%COMP%]   .t-checkmark[_ngcontent-%COMP%], tui-data-list[data-list-size=\"m\"]   [_nghost-%COMP%]   .t-checkmark[_ngcontent-%COMP%]{-webkit-margin-end:-.125rem;margin-inline-end:-.125rem}.t-dummy[_ngcontent-%COMP%]{width:1.75rem}"],
  changeDetection: 0
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiSelectOptionComponent, [{
    type: Component,
    args: [{
      selector: 'tui-select-option',
      templateUrl: './select-option.template.html',
      styleUrls: ['./select-option.style.less'],
      changeDetection: ChangeDetectionStrategy.OnPush
    }]
  }], function () {
    return [{
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_COMMON_ICONS]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [POLYMORPHEUS_CONTEXT]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Inject,
        args: [TUI_DATA_LIST_HOST]
      }]
    }, {
      type: i0.ElementRef,
      decorators: [{
        type: Inject,
        args: [ElementRef]
      }]
    }, {
      type: i1.TuiOptionComponent,
      decorators: [{
        type: Inject,
        args: [TuiOptionComponent]
      }]
    }, {
      type: i1.TuiDataListComponent,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [TuiDataListComponent]
      }]
    }, {
      type: i3.NgControl,
      decorators: [{
        type: Inject,
        args: [NgControl]
      }]
    }, {
      type: i4.AbstractTuiControl,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [AbstractTuiControl]
      }]
    }];
  }, null);
})();
const TUI_SELECT_OPTION = new PolymorpheusComponent(TuiSelectOptionComponent);
class TuiSelectOptionModule {}
TuiSelectOptionModule.ɵfac = function TuiSelectOptionModule_Factory(t) {
  return new (t || TuiSelectOptionModule)();
};
TuiSelectOptionModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TuiSelectOptionModule
});
TuiSelectOptionModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule, TuiSvgModule, TuiScrollIntoViewModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TuiSelectOptionModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TuiSvgModule, TuiScrollIntoViewModule],
      declarations: [TuiSelectOptionComponent],
      exports: [TuiSelectOptionComponent]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { TUI_SELECT_OPTION, TuiSelectOptionComponent, TuiSelectOptionModule };
